import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent,
  CardHeader,
  ListItemIcon,
  Breadcrumbs,
  CircularProgress,
  Grow
} from '@mui/material';
import NextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { t } from 'i18next';
import { createUrlItem } from 'pages/Routes';
import { hoveredStyle, notHoveredStyle } from './HoverStyles';

export const SearchResultsList = ({
  title,
  resourceTypesIcons,
  isLoading,
  onItemClick,
  filteredGroupedOptions,
  selectedItem,
  setSelectedItem
}) => {
  return (
    <Grow in={true} mountOnEnter unmountOnExit {...{ timeout: 800 }}>
      <Card>
        <CardHeader title={title} avatar={<SearchIcon />} />
        <CardContent style={{ paddingTop: 0 }}>
          <List style={{ height: '300px', minHeight: '50px', overflow: 'auto', paddingTop: 0 }}>
            <ListItems
              filteredGroupedOptions={filteredGroupedOptions}
              isLoading={isLoading}
              selectedItem={selectedItem}
              onItemClick={onItemClick}
              resourceTypesIcons={resourceTypesIcons}
              setSelectedItem={setSelectedItem}
            />
          </List>
        </CardContent>
      </Card>
    </Grow>
  );
};

const ListItems = ({
  filteredGroupedOptions,
  isLoading,
  selectedItem,
  onItemClick,
  resourceTypesIcons,
  setSelectedItem
}) => {
  const history = useHistory();
  const hoveredItem = useRef(null);

  useEffect(() => {
    hoveredItem.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    });
  }, [selectedItem]);

  if (isLoading) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (filteredGroupedOptions && Object.values(filteredGroupedOptions).length > 0) {
    return Object.keys(filteredGroupedOptions).map((resourceType) => {
      return (
        <div key={resourceType}>
          <ListItem>
            <Typography component='h4'>
              {t(`resourceType.${resourceType}`)?.toUpperCase()}
            </Typography>
          </ListItem>
          {filteredGroupedOptions[resourceType].map((opt) => (
            <ListItem
              style={_.isEqual(opt, selectedItem) ? hoveredStyle : notHoveredStyle}
              ref={_.isEqual(opt, selectedItem) ? hoveredItem : null}
              key={opt.resourceIdentifier}
              onClick={() => {
                const url = createUrlItem(resourceType, opt.resourceIdentifier);
                history.push(url);
                if (onItemClick) onItemClick();
              }}
              onMouseOver={() => setSelectedItem(opt)}>
              <ListItemIcon>{resourceTypesIcons[resourceType]}</ListItemIcon>
              <ListItemText style={{ paddingLeft: '8px' }}>
                {opt.name}
                <ParentName parentName={opt.parentName} />
              </ListItemText>
            </ListItem>
          ))}
        </div>
      );
    });
  }
  return (
    <Typography variant='body1' style={{ textAlign: 'center', padding: 100 }}>
      {t('searchWidget.noResFound')}
    </Typography>
  );
};

const ParentName = ({ parentName }) => {
  if (parentName) {
    const parentNames = parentName.split(':');
    return (
      <Breadcrumbs separator={<NextIcon fontSize='small' />} aria-label='breadcrumb'>
        {parentNames.map((element) => {
          if (element === '') {
            return null;
          } else {
            return <Typography key={element}>{element}</Typography>;
          }
        })}
      </Breadcrumbs>
    );
  } else {
    return '';
  }
};
