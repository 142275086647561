import { combineReducers } from 'redux';
import crudReducers from './utils';

export default combineReducers({
  ...crudReducers({
    resource: 'PHYSICAL_FIELD',
    keyProperty: 'uuid',
    parentField: 'physicalEntity',
    parentIdentifier: 'uuid'
  })
});
