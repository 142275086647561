import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from '../utils';
import { combineReducers } from 'redux';
import stages from './stages';
import {
  FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER,
  FETCH_DATA_PRODUCT_MESH,
  MODIFY_DATA_PRODUCT,
  DELETE_DATA_PRODUCT,
  FETCH_DATA_PRODUCT_DOMAINS,
  SEARCH_DATA_PRODUCT_DOMAINS,
  FETCH_DATA_PRODUCT_PORT,
  FETCH_DATA_PRODUCT_PORT_DEPENDENCIES,
  FETCH_DATA_PRODUCT_RELATED_LOGICAL_FIELDS,
  FETCH_DATA_PRODUCT_RELATED_DATA_CATEGORIES
} from 'constants/ActionTypes';
import filtersReducer from '../utils/filters';
import _ from 'lodash';

const filtersDefault = {
  search: '',
  domains: []
};

const mesh = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_MESH:
      return {
        ...state,
        [action.payload.dataProductUuid]: action.payload
      };
    default:
      return state;
  }
};

const domains = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_DOMAINS:
      return { ...state, ...action.payload };
    case SEARCH_DATA_PRODUCT_DOMAINS:
      return { ...state, ...{ searchContent: action?.payload?.content } };
    default:
      return state;
  }
};

const dataProductByPortIdentifier = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_BY_PORT_IDENTIFIER:
      return {
        ...state,
        [action.payload.portIdentifier]: _.omit(action.payload, 'portIdentifier')
      };
    case MODIFY_DATA_PRODUCT:
    case DELETE_DATA_PRODUCT:
      return {
        ..._.omit(
          state,
          Object.keys(state).filter((key) => state[key].uuid === action.payload.uuid)
        )
      };
    default:
      return state;
  }
};

const ports = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_PORT:
      return {
        ...state,
        [action.payload.uuid]: action.payload
      };
    case MODIFY_DATA_PRODUCT:
    case DELETE_DATA_PRODUCT:
      return {};
    default:
      return state;
  }
};

const portsDependencies = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_PORT_DEPENDENCIES:
      return {
        ...state,
        [action.payload.dataProductPortUuid]: action.payload
      };
    case MODIFY_DATA_PRODUCT:
    case DELETE_DATA_PRODUCT:
      return {};
    default:
      return state;
  }
};

const relatedDataCategoriesByDataProduct = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_RELATED_DATA_CATEGORIES:
      return {
        ...state,
        [action.payload.dataProductUuid]: action.payload
      };
    default:
      return state;
  }
};

export const selectRelatedDataCategories =
  ({ dataProductUuid }) =>
  (state) =>
    state.dataproducts.products.relatedDataCategoriesByDataProduct[dataProductUuid];

const relatedLogicalFieldsByDataProductAndDataCategory = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_RELATED_LOGICAL_FIELDS:
      return {
        ...state,
        [`${action.payload.dataProductUuid}&${action.payload.dataCategoryUuid}`]: action.payload
      };
    default:
      return state;
  }
};

export const selectRelatedLogicalFields =
  ({ dataProductUuid, dataCategoryUuid }) =>
  (state) =>
    state.dataproducts.products.relatedLogicalFieldsByDataProductAndDataCategory[
      `${dataProductUuid}&${dataCategoryUuid}`
    ];

export default combineReducers({
  mesh,
  domains,
  dataProductByPortIdentifier,
  ports,
  stages,
  portsDependencies,
  relatedDataCategoriesByDataProduct,
  relatedLogicalFieldsByDataProductAndDataCategory,
  ...crudReducers({ resource: ResourceTypes.DATA_PRODUCT, keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: ResourceTypes.DATA_PRODUCT, filtersDefault: filtersDefault })
});
