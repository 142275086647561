const EngagementCategories = {
  LOGICAL_MODEL: 'LOGICAL_MODEL',
  PHYSICAL_MODEL: 'PHYSICAL_MODEL',
  DATA_FLOW: 'DATA_FLOW',
  DATA_QUALITY: 'DATA_QUALITY',
  DATA_QUALITY_PROBES: 'DATA_QUALITY_PROBES',
  DATA_QUALITY_ASSESSMENT: 'DATA_QUALITY_ASSESSMENT',
  DATA_QUALITY_OBSERVABILITY: 'DATA_QUALITY_OBSERVABILITY',
  DATA_ACTOR: 'DATA_ACTOR',
  STEWARDSHIP: 'STEWARDSHIP',
  GRAPH: 'GRAPH',
  CLASSIFICATION: 'CLASSIFICATION',
  CONSENTS: 'CONSENTS',
  PROCESSINGS: 'PROCESSINGS',
  TASKS: 'TASKS',
  PROPOSALS: 'PROPOSALS',
  CAMPAIGNS: 'CAMPAIGNS',
  ISSUES: 'ISSUES',
  SETTINGS: 'SETTINGS',
  TEMPLATES: 'TEMPLATES',
  QUERY_PARSER: 'QUERY_PARSER',
  GOVERNANCE_POLICIES: 'GOVERNANCE_POLICIES',
  GOVERNANCE_POLICY_SUITES: 'GOVERNANCE_POLICY_SUITES',
  DATA_PRODUCTS: 'DATA_PRODUCTS'
};

export default EngagementCategories;
