import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import Spinner from '../../components/spinner/Spinner';
import { hideMessage } from '../../actions/BehaviourActions';
import { connect } from 'react-redux';
import './BehaviouralContainer.css';

const BehaviouralContainer = ({
  onHideMessage,
  showProgress,
  showMessage,
  messageText,
  showSpinner,
  children
}) => (
  <>
    {children}
    <Snackbar
      open={showMessage}
      message={messageText}
      autoHideDuration={4000}
      onClose={onHideMessage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />

    <LinearProgress
      id='behavioural-progress'
      mode='indeterminate'
      style={{ display: showProgress ? 'inline' : 'none' }}
    />
    <Spinner show={showSpinner} />
  </>
);

BehaviouralContainer.propTypes = {
  onHideMessage: PropTypes.func.isRequired,
  showMessage: PropTypes.bool.isRequired,
  messageText: PropTypes.string,
  showProgress: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  showMessage: state.behaviour.message.show,
  messageText: state.behaviour.message.text,
  showProgress: state.behaviour.progress.show,
  showSpinner: state.behaviour.spinner.show
});

export default connect(mapStateToProps, { onHideMessage: hideMessage })(BehaviouralContainer);
