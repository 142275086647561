import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import { combineReducers } from 'redux';

export default combineReducers({
  ...crudReducers({
    resource: ResourceTypes.DATA_PRODUCT_STAGE,
    keyProperty: 'uuid',
    parentField: 'dataProductUuid'
  })
});
